import React, { forwardRef, useState } from 'react';
import {
  Model,
  ModelId,
  ModelType,
  Contact,
  Company,
  CompanyTableColumn,
  ContactTableColumn,
} from 'types';
import { Checkbox } from '@demandscience/ui';
import { debounce, map, isEmpty } from 'lodash';
import Table, {
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
} from 'components/Layout/Table';
import { DataGridRecordActions } from './DataGridRecordActions';
import { stopPropagation } from 'utils/event';
import ExportProvider from 'components/Export/ExportProvider';
import useCustomization from 'components/SearchCustomization/useCustomization';
import DataGridTableHeaderCell from './DataGridTableHeaderCell';
import DataGridTableCompanyCell from './DataGridTableCompanyCell';
import DataGridTableContactCell from './DataGridTableContactCell';

interface DataGridTableLayoutProps {
  className?: string;
  dense?: boolean;
  footer?: React.ReactNode;
  handleDetailsShow: (row: Model) => () => void;
  handleRowSelect: (row: Model) => (selected: boolean) => void;
  isList?: boolean;
  isSelected: (row: ModelId, index: number) => boolean;
  kind: ModelType;
  rows: Model[];
  selectedRow?: Model;
  selectionDisabled?: boolean;
}

const NUMBER_OF_ROWS_TO_DISPLAY_DETAILS_ON_TOP = 5;

const DataGridTableLayout = forwardRef<HTMLDivElement, DataGridTableLayoutProps>(
  (
    {
      className,
      dense,
      kind,
      rows,
      isSelected,
      selectionDisabled,
      selectedRow,
      handleRowSelect,
      handleDetailsShow,
      isList = false,
      footer,
    },
    ref,
  ) => {
    const {
      results: { companiesTableColums, contactsTableColums },
    } = useCustomization();
    const [showBackdrop, setShowBackdrop] = useState(false);

    const getColumnInitialWidth = (column: string) => {
      const wideColumns = [
        'industry',
        'job_title',
        'company',
        'subindustry',
        'company_address',
        'company_location',
        'contact_location',
        'job_function',
        'intent_topic',
        'install_topic',
        'specialities',
      ];
      if (column === 'company_name' || column === 'contact_name') {
        return 250;
      } else {
        return wideColumns.includes(column) ? 250 : 120;
      }
    };

    const tableColumns = kind === ModelType.Company ? companiesTableColums : contactsTableColums;
    const initialColumnWidths = tableColumns.map(getColumnInitialWidth);
    const handleScroll = debounce(
      (e: any) => {
        // Scroll handler to show or hide backdrops
        setShowBackdrop(e.target.scrollLeft > 0);
      },
      150,
      { leading: true },
    );

    if (isEmpty(rows)) {
      return null;
    }

    return (
      <Table
        className={className}
        onScroll={handleScroll}
        ref={ref}
        initialColumnWidths={initialColumnWidths}
        renderContent={(columnWidths, handleMouseDown) => (
          <>
            <TableHeader className="sticky top-0 z-[3]" fullWidth dense={dense}>
              <TableHeaderCell className="left-0 z-[4]" sticky>
                <div className="w-5" />
              </TableHeaderCell>
              {map(tableColumns, (column, index) => (
                <DataGridTableHeaderCell
                  key={column}
                  type={kind}
                  isList={isList}
                  column={column}
                  initialWidth={columnWidths[index]}
                  onResize={handleMouseDown(index)}
                  shadowRight={
                    (column === 'company_name' || column === 'contact_name') && showBackdrop
                  }
                />
              ))}
              <TableHeaderCell width={60} className="flex justify-end">
                Actions
              </TableHeaderCell>
            </TableHeader>
            <TableBody>
              {map(rows, (row: Company | Contact, index: number) => {
                const active = selectedRow?.dsid === row.dsid;
                const selected = isSelected(row, index);
                const isLastIndex = row === rows[rows.length - 1];
                const displayDetailsOnTop =
                  isLastIndex && rows.length >= NUMBER_OF_ROWS_TO_DISPLAY_DETAILS_ON_TOP;

                return (
                  <TableRow
                    key={row.dsid}
                    active={active}
                    selected={selected}
                    clickable
                    onClick={handleDetailsShow(row)}
                    fullWidth
                    dense={dense}
                  >
                    <TableCell
                      className="px-2 z-[1] left-0 w-[52px]"
                      sticky
                      onClick={stopPropagation}
                    >
                      <Checkbox
                        checked={selected}
                        onChange={handleRowSelect(row)}
                        disabled={selectionDisabled}
                      />
                    </TableCell>
                    {map(tableColumns, (column, colIndex) =>
                      kind === ModelType.Company ? (
                        <DataGridTableCompanyCell
                          key={column}
                          column={column as CompanyTableColumn}
                          shadowRight={column === 'company_name' && showBackdrop}
                          row={row as Company}
                          displayContactDetailsOnTop={displayDetailsOnTop}
                          width={columnWidths[colIndex]} // Ensure this width is consistent across all cells
                        />
                      ) : (
                        <DataGridTableContactCell
                          key={column}
                          column={column as ContactTableColumn}
                          shadowRight={column === 'contact_name' && showBackdrop}
                          row={row as Contact}
                          displayContactDetailsOnTop={displayDetailsOnTop}
                          width={columnWidths[colIndex]} // Ensure this width is consistent across all cells
                        />
                      ),
                    )}

                    <TableCell width={20}>
                      <div className="flex justify-end items-center" onClick={stopPropagation}>
                        <ExportProvider modelType={kind} selectionCount={1}>
                          <DataGridRecordActions
                            record={row}
                            displayContactDetailsOnTop={displayDetailsOnTop}
                          />
                        </ExportProvider>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            {footer}
          </>
        )}
      />
    );
  },
);

DataGridTableLayout.displayName = 'DataGridTableLayout';

export default DataGridTableLayout;
