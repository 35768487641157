import XIcon from '@demandscience/ui/icons/x';
import ChevronDownIcon from '@demandscience/ui/icons/chevron-down';

import { CreditsActivityFilter, CreditActivityContext } from 'types';

import Dropdown from 'components/Filter/Dropdown';
import { includes, isEmpty, isEqual, map, omit } from 'lodash';
import Search from 'components/Input/Search';
import classNames from 'classnames';
import CheckboxMenuItem from 'components/Filter/Dropdown/CheckboxMenuItem';
import DividerMenuItem from 'components/Filter/Dropdown/DividerMenuItem';
import TextMenuItem from 'components/Filter/Dropdown/TextMenuItem';
import { useCallback, useRef, useState } from 'react';
import {
  creditActivityActions,
  creditActivityContexts,
  creditActivityRecordTypes,
  getFilterValue,
  getSelectLabel,
  getSelectValue,
} from './utils';
import Datepicker from 'components/DatePicker/DatePicker';
import { formatISO } from 'date-fns';

interface FiltersProps {
  onChange: (value: CreditsActivityFilter) => void;
  value?: CreditsActivityFilter;
}
export const timePeriod = {
  today: 'Today',
  'last 7 days': 'Last 7 days',
  'last 30 days': 'Last 30 days',
  'this month': 'This month',
  'last month': 'Last month',
};

const Filters: React.FC<FiltersProps> = ({ value, onChange }) => {
  const selectValue = getSelectValue(value);
  const [openDatepicker, setOpenDatepicker] = useState<boolean>(false);
  const chevronRef = useRef<HTMLButtonElement | null>(null);

  const handleChange = useCallback(
    (field: string, fieldValue: any) => {
      if (isEmpty(fieldValue)) {
        onChange(omit(value, field));
      } else {
        onChange({
          ...value,
          [field]: fieldValue,
        });
      }
    },
    [onChange, value],
  );

  const handleSearchChange = useCallback(
    (freetext: string | undefined) => {
      handleChange('account_freetext', freetext);
    },
    [handleChange],
  );

  const handleSelectChange = useCallback(
    (newValue: any) => {
      // Handle case where user selects 'all' or clears the selection
      if (isEmpty(newValue) || (!includes(selectValue, 'all') && includes(newValue, 'all'))) {
        setOpenDatepicker(false);
        onChange(
          omit(
            value,
            'record_types',
            'action_contexts',
            'actions',
            'destinations',
            'time_period',
            'date_from',
            'date_to',
          ),
        );
      } else {
        let hasCustom = false;
        let customFilter: any = null;

        const addedValues = newValue.filter((val: any) => !includes(selectValue, val));

        const timePeriodKeys = Object.keys(timePeriod);

        const addedCustom = includes(addedValues, 'custom');
        const addedTimePeriod = addedValues.find((val: any) => includes(timePeriodKeys, val));

        if (addedCustom) {
          newValue = newValue.filter((val: any) => !includes(timePeriodKeys, val));
        }

        if (addedTimePeriod) {
          newValue = newValue.filter((val: any) => val !== 'custom');
          delete value?.date_from;
          delete value?.date_to;
        }

        newValue.forEach((val: any) => {
          if (val === 'custom') {
            hasCustom = true;
          } else if (typeof val === 'object' && val.date_from && val.date_to) {
            customFilter = val;
          }
        });

        const filteredNewValue = newValue.filter(
          (val: any) =>
            val !== 'custom' && !(typeof val === 'object' && val.date_from && val.date_to),
        );

        const res = getFilterValue(filteredNewValue);

        let newFilters = {
          ...value,
          ...res,
        };

        if (hasCustom) {
          delete newFilters.time_period;

          if (customFilter && customFilter.date_from && customFilter.date_to) {
            newFilters.date_from = customFilter.date_from;
            newFilters.date_to = customFilter.date_to;
          } else if (value?.date_from && value.date_to) {
          } else {
            if (chevronRef.current) {
              chevronRef.current.click();
            }
            setOpenDatepicker(true);
            return;
          }
        } else {
          if (res.time_period) {
            delete newFilters.date_from;
            delete newFilters.date_to;
          }
        }

        onChange(newFilters);

        // Close the date picker if 'custom' is not selected
        if (!includes(newValue, 'custom')) {
          setOpenDatepicker(false);
        }
      }
    },
    [onChange, selectValue, value],
  );

  const handleClearAll = useCallback(() => {
    onChange({});
    setOpenDatepicker(false);
  }, [onChange]);

  const [dates, setDates] = useState<[string | null, string | null]>([null, null]);

  const handleDateChange = useCallback((newDates: [string | null, string | null]) => {
    setDates(newDates);
  }, []);

  const handleCustomConfirm = () => {
    const [dateFrom, dateTo] = dates;

    // Format the dates in ISO format
    if (!dateFrom || !dateTo) {
      return;
    }
    const formattedDateFrom = formatISO(new Date(dateFrom));
    const formattedDateTo = formatISO(new Date(dateTo));

    handleSelectChange(['custom', { date_from: formattedDateFrom, date_to: formattedDateTo }]);
  };

  return (
    <>
      <div className="md:w-64">
        <Search
          placeholder="Filter list by team member"
          value={value?.account_freetext}
          onChange={handleSearchChange}
        />
      </div>
      <Dropdown
        menuClassName="max-h-auto"
        value={selectValue}
        onChange={handleSelectChange}
        multiple
        matchContentSize
        button={({ open, value }) => (
          <button
            ref={chevronRef}
            className="px-4 py-2 text-sm text-gray-700 text-left w-full md:w-52 flex flex-row items-center gap-1 border-none rounded ring-inset ring-1 ring-gray-300 hover:ring-gray-700 focus:ring-primary-500 focus:hover:ring-primary-500 focus:outline-none"
          >
            <div className="grow ellipsis">{getSelectLabel(value)}</div>
            {!isEqual(value, ['all']) && (
              <XIcon size={18} className="flex-shrink-0 text-gray-500" onClick={handleClearAll} />
            )}
            <ChevronDownIcon
              size={18}
              className={classNames('flex-shrink-0', { 'rotate-180': open })}
            />
          </button>
        )}
      >
        <CheckboxMenuItem option={{ id: 'all', label: 'All credits logs', value: 'all' }} />
        <DividerMenuItem />
        <TextMenuItem textClassName="text-xs text-gray-500 py-[2px]">Action</TextMenuItem>
        {map(creditActivityActions, (label, value) => (
          <CheckboxMenuItem key={value} option={{ id: value, label, value }} />
        ))}
        <DividerMenuItem />
        <TextMenuItem textClassName="text-xs text-gray-500 py-[2px]">Type</TextMenuItem>
        {map(creditActivityRecordTypes, (label, value) => (
          <CheckboxMenuItem key={value} option={{ id: value, label, value }} />
        ))}
        <DividerMenuItem />
        <TextMenuItem textClassName="text-xs text-gray-500 py-[2px]">Context</TextMenuItem>
        {map([CreditActivityContext.Webapp, CreditActivityContext.Extension], (value) => (
          <CheckboxMenuItem
            key={value}
            option={{ id: value, value, label: creditActivityContexts[value] }}
          />
        ))}
        <DividerMenuItem />
        <TextMenuItem textClassName="text-xs text-gray-500 py-[2px]">Date</TextMenuItem>
        <CheckboxMenuItem option={{ id: 'custom', label: 'Custom', value: 'custom' }} />
        {map(timePeriod, (label, value) => (
          <CheckboxMenuItem key={value} option={{ id: value, label, value }} />
        ))}
      </Dropdown>
      {openDatepicker && (
        <Datepicker
          onChange={handleDateChange}
          onConfirm={handleCustomConfirm}
          limitStartDate={new Date(1880, 0, 1)} // Optional limit to Jan 1, 1880, to prevent users from entering dates before 1880
        />
      )}
    </>
  );
};

export default Filters;
